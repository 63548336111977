<style lang="less" scoped>
  .department-page {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  .toolbox {
    padding: 10px;
    background-color: #FFF;
    border-radius: 5px;
  }
  .query-form {
    margin-top: 10px;
  }
  .table {
    margin-top: 10px;
    height: 0;
    flex: 1;
  }
  .title {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
</style>

<template>
  <div class="department-page">
    <div class="toolbox">
      <div class="title">
        <fm-title title-text="绩效员工" :note-text="'共' + showDataList.length + '人'"></fm-title>
        <div>
          <Button style="margin-right: 10px;" icon="md-refresh-circle" :loading="loading.load" @click="loadData">刷新</Button>
          <Button v-if="$authFunsProxyNoRoute['performance.worker-positionGet']" style="margin-right: 10px;" icon="ios-apps" @click="status.positionPage = true">绩效职务管理</Button>
          <Button style="margin-right: 10px;" v-if="$authFunsProxyNoRoute['performance.worker-config']" icon="md-construct" @click="workerConfigClick">员工月度明细默认配置</Button>
          <Poptip style="margin-right: 10px;" v-if="$authFunsProxyNoRoute['performance.worker-manage']" confirm @on-ok="dataSync" title="确定同步吗？">
            <Button :loading="loading.sync" type="warning" icon="md-sync">数据同步</Button>
          </Poptip>
          <Button v-if="$authFunsProxyNoRoute['performance.worker-manage']" icon="md-add-circle" type="primary" @click="addClick">新增绩效员工</Button>
        </div>
      </div>
      <Row class="query-form" align="middle" :gutter="10">
        <i-col>
          <i-input placeholder="请输入搜索内容" clearable search @on-blur="onSearch" @on-search="onSearch" @on-clear="onSearch" v-model="queryForm.searchKey">
            <Select @on-change="onSearch" v-model="queryForm.searchType" slot="prepend" style="width: 110px">
              <Option value="name">姓名</Option>
              <Option value="idNo">身份证号码</Option>
              <Option value="phone">电话号码</Option>
              <Option value="hisCode">his编码</Option>
            </Select>
          </i-input>
        </i-col>
        <i-col>科室</i-col>
        <i-col>
          <Select @on-change="onSearch" @on-clear="onSearch" placeholder="请选择科室" filterable clearable v-model="queryForm.pfmOrgId">
            <Option v-for="item in pfmOrgList" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </i-col>
        <i-col>职务</i-col>
        <i-col>
          <Select @on-change="onSearch" @on-clear="onSearch" placeholder="请选择职务" filterable clearable v-model="queryForm.pfmPositionId">
            <Option v-for="item in pfmPositionList" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </i-col>
        <i-col>关联用户</i-col>
        <i-col>
          <Select @on-change="onSearch" @on-clear="onSearch" placeholder="请选择关联用户" filterable clearable v-model="queryForm.userId">
            <Option v-for="item in userList" :value="item.key" :key="item.key">{{ item.label }}</Option>
          </Select>
        </i-col>
        <i-col>状态</i-col>
        <i-col>
          <Dropdown trigger="click" @on-click="name => onSearch({status: name})">
            <Button type="primary" v-if="queryForm.status === 'yes'">正常<Icon type="ios-arrow-down"></Icon></Button>
            <Button type="warning" v-else-if="queryForm.status === 'no'">无效<Icon type="ios-arrow-down"></Icon></Button>
            <Button type="default" v-else>全部<Icon type="ios-arrow-down"></Icon></Button>
            <DropdownMenu slot="list">
              <DropdownItem :selected="queryForm.status === 'all'" name="all">全部</DropdownItem>
              <DropdownItem :selected="queryForm.status === 'yes'" name="yes">正常</DropdownItem>
              <DropdownItem :selected="queryForm.status === 'no'" name="no">无效</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </i-col>
      </Row>
    </div>
    <div class="table" ref="table">
      <Table :loading="loading.load" v-if="tableHeight !== null" :height="tableHeight" :columns="columns" :data="pageDataList">
        <template slot-scope="{ row }" slot="action">
          <Button @click="itemEdit(row)" icon="ios-brush" size="small" type="text">修改</Button>
          <Poptip transfer confirm @on-ok="itemDel(row)" title="确定删除吗？">
            <Button icon="md-trash" size="small" type="text">删除</Button>
          </Poptip>
        </template>
      </Table>
    </div>
    <div style="padding: 10px;background-color: #FFF;">
      <Page @on-change="num => page.num = num" :total="showDataList.length" :current="page.num" :page-size="page.size" show-total show-elevator />
    </div>
    <Drawer title="绩效职务管理" draggable :mask-closable="false" width="40" placement="right" closable v-model="status.positionPage">
      <PositionPage v-if="status.positionPage" style="height: 100%;" />
    </Drawer>
    <Drawer :title="(formData.id ? '修改' : '新增') + '绩效员工'" draggable :mask-closable="false" width="30" placement="right" closable v-model="status.form">
      <fm-form label-alone label-align="left">
        <fm-form-item label="名称">
          <fm-input-new placeholder="请输入名称" v-model="formData.name" />
        </fm-form-item>
        <fm-form-item label="身份证号码">
          <fm-input-new placeholder="请输入身份证号码" v-model="formData.idNo" />
        </fm-form-item>
        <fm-form-item label="电话号码">
          <fm-input-new placeholder="请输入电话号码" v-model="formData.phone" />
        </fm-form-item>
        <fm-form-item label="his编码">
          <fm-input-new placeholder="请输入his编码" v-model="formData.hisCode" />
        </fm-form-item>
        <fm-form-item label="关联用户">
          <Select placeholder="请选择关联用户" filterable clearable v-model="formData.userId">
            <Option v-for="item in userList" :value="item.key" :key="item.key">{{ item.label }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="所属科室">
          <Select placeholder="请选择所属科室" filterable clearable v-model="formData.pfmOrgId">
            <Option v-for="item in pfmOrgList" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="所属职务">
          <Select placeholder="请选择所属职务" filterable clearable v-model="formData.pfmPositionId">
            <Option v-for="item in pfmPositionList" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="状态">
          <fm-switch :on-value="0" :off-value="1" v-model="formData.status">
            <span slot="on">正常</span>
            <span slot="off">无效</span>
          </fm-switch>
        </fm-form-item>
        <fm-form-item label="">
          <div style="display: flex;">
            <Button style="flex: 1;" @click="status.form = false" type="default">取消</Button>
            <Button style="margin-left: 10px;flex: 1;" @click="submit" type="info">确定{{(formData.id ? '修改' : '新增')}}</Button>
          </div>
        </fm-form-item>
      </fm-form>
    </Drawer>
  </div>
</template>

<script>
import { request } from '@/api'
import PositionPage from '../position/index.vue'

function getDefaultQuery () {
  return {
    searchKey: null,
    searchType: 'name',
    pfmOrgId: null,
    pfmPositionId: null,
    userId: null,
    status: 'yes'
  }
}

function getDefaultFormData () {
  return {
    id: null,
    name: null,
    idNo: null,
    phone: null,
    status: 0,
    hisCode: null,
    userId: null,
    pfmOrgId: null,
    pfmPositionId: null
  }
}

export default {
  components: { PositionPage },
  data () {
    return {
      dataList: [],
      query: getDefaultQuery(),
      queryForm: getDefaultQuery(),
      formData: getDefaultFormData(),
      status: {
        positionPage: false,
        form: false
      },
      loading: {
        load: false,
        submit: false,
        sync: false
      },
      tableHeight: null,
      page: {
        num: 1,
        size: 10,
        total: 0
      }
    }
  },
  computed: {
    columns () {
      const columns = [
        { title: '姓名', key: 'name', width: 120, ellipsis: true, tooltip: true },
        { title: '身份证号码', key: 'idNo', width: 180, ellipsis: true, tooltip: true },
        { title: '电话号码', key: 'phone', width: 125, ellipsis: true, tooltip: true },
        { title: '关联用户', key: 'userName' },
        { title: '所属科室', key: 'pfmOrgName' },
        { title: '所属职务', key: 'pfmPositionName'  },
        { title: 'his编码', key: 'hisCode' },
        {
          title: '状态', key: 'status', width: 100, render (h, { row } ) {
            return h('Tag', {
              props: {
                color: row.status === 0 ? 'green' : 'volcano'
              }
            }, row.status === 0 ? '正常' : '无效')
          }
        },
      ]

      if (this.$authFunsProxyNoRoute['performance.worker-manage']) {
        return [
          ...columns,
          {
            title: '操作', key: 'action', slot: 'action', width: 170
          }
        ]
      }

      return columns
    },
    showDataList () {
      const query = this.query
      const status = query.status === 'all' ? null : (query.status === 'yes' ? 0 : 1)
      return this.dataList.filter(item => {
        if (query.searchKey && query.searchType && String(item[query.searchType]).indexOf(query.searchKey) < 0) {
          return false  
        }
        if (query.pfmOrgId && query.pfmOrgId !== item.pfmOrgId) {
          return false
        }
        if (query.pfmPositionId && query.pfmPositionId !== item.pfmPositionId) {
          return false
        }
        if (query.userId && query.userId !== item.userId) {
          return false
        }
        if (status !== null && item.status !== status) {
          return false
        }
        return true
      })
    },
    pageDataList () {
      const start = (this.page.num - 1) * this.page.size
      const end = start + this.page.size
      return this.showDataList.slice(start, end)
    },
    pfmOrgList () {
      return this.$store.getters.pfmOrgList
    },
    pfmPositionList () {
      return this.$store.getters.pfmPositionList
    },
    userList () {
      return this.$store.getters.userList
    }
  },
  methods: {
    onSearch (query) {
      if (query) {
        Object.assign(this.queryForm, query)
      }
      this.query = JSON.parse(JSON.stringify(this.queryForm))
      this.page.num = 1
    },
    async dataSync () {
      this.loading.sync = true
      try {
        await request('/performance/pfm_worker/sync', 'post')
        this.loadData()
      } catch (e) {
        console.error(e)
      }
      this.loading.sync = false
    },
    workerConfigClick () {
      this.$router.push({
        name: 'performance.worker.config',
        query: { back: true }
      })
    },
    itemEdit (item) {
      this.formData = JSON.parse(JSON.stringify(item))
      this.status.form = true
    },
    async itemDel (item) {
      const confirm = await this.$dialog.confirm('确定删除吗？')
      if (confirm) {
        await request('/performance/pfm_worker/' + item.id, 'delete')
        this.$Message.success('已删除')
        this.loadData()
      }
    },
    addClick () {
      this.formData = getDefaultFormData()
      this.status.form = true
    },
    async loadData () {
      if (this.$authFunsProxyNoRoute['performance.worker-get']) {
        this.loading.load = true
        const dataList = await request('/performance/pfm_worker', 'get')
        dataList.forEach(v => v.status = Number(v.status))
        this.dataList = dataList
        this.loading.load = false
      }
    },
    resize () {
      this.tableHeight = this.$refs.table.offsetHeight
      this.page.size = Math.ceil((this.tableHeight - 40) / 48)
    },
    async submit () {
      this.loading.submit = true
      try {
        if (this.formData.id) {
          await request('/performance/pfm_worker/' + this.formData.id, 'put', this.formData)
        } else {
          await request('/performance/pfm_worker', 'post', this.formData)
        }
        this.status.form = false
        this.loading.submit = false
        this.$Message.success('操作成功')
        this.loadData()
      } catch (e) {
        this.loading.submit = false
      }
    }
  },
  mounted() {
    this.loadData()
    this.$store.dispatch('loadUserList')
    this.$store.dispatch('loadPfmOrgList')
    this.$store.dispatch('loadPfmPositionList')
    window.addEventListener('resize', this.resize)
    this.resize()
  },
  destroyed() {
    window.removeEventListener('resize', this.resize)
  },
}
</script>
<style lang="less" scoped>
  .position-page {
    display: flex;
    flex-direction: column;
  }
</style>

<template>
  <div class="position-page">
    <div style="flex: 1;height: 0;overflow: auto;" v-loadingx="loading.load">
      <template v-if="status.form">
          <fm-title :title-text="(formData.id ? '修改' : '新增') + '绩效职务'"></fm-title>
          <fm-form label-alone label-align="left">
            <fm-form-item label="名称">
              <fm-input-new v-model="formData.name" placeholder="请输入名称"/>
            </fm-form-item>
            <fm-form-item label="类型">
              <fm-input-new v-model="formData.type" placeholder="请输入类型"/>
            </fm-form-item>
            <fm-form-item label="平均绩效系数">
              <fm-input-number v-model="formData.avgPfmRate" placeholder="请输入平均绩效系数" :min="0"></fm-input-number>
            </fm-form-item>
            <fm-form-item label="his编码">
              <fm-input-new v-model="formData.hisCode" placeholder="请输入his编码"/>
            </fm-form-item>
          </fm-form>
      </template>
      <template v-else>
        <CellGroup>
          <Cell :name="index" v-for="(item, index) in dataList" :key="item.id" :title="item.name + ' - ' + (item.hisCode || '')">
            <div slot="label">
              <div>类型: {{item.type}}</div>
              <div>平均绩效系数: {{item.avgPfmRate}}</div>
            </div>
            <template slot="extra" v-if="$authFunsProxyNoRoute['performance.worker-positionManage']">
              <Button @click.stop="itemEdit(item)" style="margin-left: 10px;" size="small" type="default">编辑</Button>
              <Button @click.stop="itemDel(item)" style="margin-left: 10px;" size="small" type="default">删除</Button>
            </template>
          </Cell>
        </CellGroup>
        <TableEmpty style="display: flex;align-items: center;flex-direction: column;" v-if="!dataList.length" />
      </template>
    </div>
    <div v-if="status.form" style="margin-top: 10px;display: flex;">
      <Button :loading="loading.submit" style="flex: 1;" @click="status.form = false" type="default" long>取消</Button>
      <Button :loading="loading.submit" style="flex: 1;margin-left: 10px;" @click="submit" type="info" long>确定</Button>
    </div>
    <div v-else-if="$authFunsProxyNoRoute['performance.worker-positionManage']" style="margin-top: 10px;">
      <Button @click="addClick" type="info" long>新增绩效职务</Button>
    </div>
  </div>
</template>

<script>
import { request } from '@/api'
import TableEmpty from '@/components/base/TableEmpty.vue'

function getDefaultFormData () {
  return {
    id: null,
    name: null,
    type: null,
    avgPfmRate: null,
    hisCode: null,
  }
}

export default {
  components: { TableEmpty },
  data () {
    return {
      dataList: [],
      formData: getDefaultFormData(),
      status: {
        form: false
      },
      loading: {
        submit: false,
        load: false
      }
    }
  },
  methods: {
    async loadData () {
      if (this.$authFunsProxyNoRoute['performance.worker-positionGet']) {
        this.loading.load = true
        this.dataList = await this.$store.dispatch('loadPfmPositionList', true)
        this.loading.load = false
      }
    },
    addClick () {
      this.formData = getDefaultFormData()
      this.status.form = true
    },
    itemEdit (item) {
      if (this.$authFunsProxyNoRoute['performance.worker-positionManage']) {
        this.formData = JSON.parse(JSON.stringify(item))
        this.status.form = true
      }
    },
    async itemDel (item) {
      const confirm = await this.$dialog.confirm('确定删除吗？')
      if (confirm) {
        await request('/performance/pfm_position/' + item.id, 'delete')
        this.$Message.success('已删除')
        this.$emit('refresh')
        this.loadData()
      }
    },
    async submit () {
      try {
        this.loading.submit = true
        if (this.formData.id) {
          await request('/performance/pfm_position/' + this.formData.id, 'put', this.formData)
        } else {
          await request('/performance/pfm_position', 'post', this.formData)
        }
        this.status.form = false
        this.$Message.success('操作成功')
        this.loadData()
        this.loading.submit = false
      } catch (e) {
        this.loading.submit = false
      }
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>